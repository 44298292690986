import { render, staticRenderFns } from "./CheckboxColors.vue?vue&type=template&id=c66e7240&scoped=true&"
import script from "./CheckboxColors.vue?vue&type=script&lang=js&"
export * from "./CheckboxColors.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxColors.vue?vue&type=style&index=0&id=c66e7240&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c66e7240",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonMedia: require('/opt/atlassian/pipelines/agent/build/components/common/CommonMedia.vue').default})
